import * as React from 'react';

interface Props {
  title: string;
  subText?: string;
  classes?: string;
  Breadcrumb?: React.ReactNode;
  dataCy?: string;
}

const TileHeader = ({ title, subText, dataCy, ...props }: Props) => (
  <div className="mx-auto mt-4 max-w-base pl-2 md:pl-4">
    {!!props.Breadcrumb && props.Breadcrumb}
    <div className="heading-bold-md mt-4" data-cy={dataCy}>
      {title}
    </div>
    {subText && <div className="mt-2 font-hvMedium text-base text-gray-500">{subText}</div>}
  </div>
);

export default TileHeader;
