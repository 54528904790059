import * as React from 'react';
import cn from 'classnames';
import Link from 'styleguide/components/Links/Link';
import Span from 'styleguide/components/Span/Span';

interface Page {
  label: string;
  link?: string;
}

export interface Props {
  pages: Page[];
  className?: string;
}

const Breadcrumb = ({ pages, ...props }: Props) => (
  <div className={cn('mt-6', props.className)}>
    {pages.map((page, index) => (
      <React.Fragment key={index}>
        {' '}
        {page.link ? (
          <Link
            className="cursor-pointer !text-xs !leading-3 !text-gray-300"
            to={page.link}
            color="none"
            underline="none"
          >
            {page.label}
          </Link>
        ) : (
          <Span className="!text-xs !leading-3 !text-default">{page.label}</Span>
        )}{' '}
        {index !== pages.length - 1 && <Span className="!text-xs !leading-3">/</Span>}
      </React.Fragment>
    ))}
  </div>
);

export default Breadcrumb;
