import apiCall from 'app/libs/utils/api/apiCall';
import { buildUrl } from 'libs/utils/api';
import { AddressesResponse, OrdersResponse } from '../types';
import { SharedErrors } from 'libs/utils/api/types';
import { Order } from 'api/orders/types';

// /account
export const ordersScope = (path?) => `/account${path || ''}`;

// /account/orders
export const getOrders = (searchField, dateRange = null) => {
  const url = buildUrl(ordersScope('/orders'));
  const data = { search: searchField, date_range: dateRange };
  return apiCall.post<OrdersResponse, SharedErrors>({ url, data, unscoped: true });
};

// /account/reorder/:orderNumber
export const reorder = orderNumber => {
  const url = buildUrl(ordersScope(`/reorder/${orderNumber}`));
  return apiCall.get<Order, SharedErrors>({ url, unscoped: true });
};

// /account/default_addresses
export const updateDefaultAddresses = accountAttributes => {
  const url = buildUrl(ordersScope('/default_addresses'));
  return apiCall.post<AddressesResponse, SharedErrors>({ url, data: accountAttributes, unscoped: true });
};
