import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconSearch = ({
  title = 'Search',
  color = 'dark',
  viewBoxWidth = 19,
  viewBoxHeight = 19,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <defs>
      <path id="a" d="M0 0h19v19H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        d="M11.209 13.716v7.608h-1.167v-7.608a7 7 0 1 1 1.167 0zM6.5 10.865a5.833 5.833 0 1 0 8.25-8.25 5.833 5.833 0 0 0-8.25 8.25z"
        fill={colors[color]}
        fillRule="nonzero"
        mask="url(#b)"
        transform="rotate(-46 10.625 10.532)"
      />
    </g>
  </SvgIcon>
);

export default IconSearch;
